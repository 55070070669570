<template>
  <v-data-table
    :headers="displayHeaders"
    :items="items"
    v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }"
    :key="anIncreasingNumber"
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-data-table>
</template>

<script>
import Sortable from "sortablejs";

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver(mutationsList => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === "attributes" && mutation.attributeName === "class") {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add("sortHandle");
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

export default {
  name: "DraggableHeaderTable",
  props: {
    headers: Array,
    items: Array,
    value: Object
  },
  data: () => ({
    anIncreasingNumber: 1,
    displayHeaders: []
  }),
  directives: {
    "sortable-table": {
      inserted: (el, binding) => {
        el.querySelectorAll("th:not(.data-table-expand-header)").forEach(draggableEl => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, "sortHandle");
          draggableEl.classList.add("sortHandle");
        });
        Sortable.create(el.querySelector("tr"), binding.value ? { ...binding.value, handle: ".sortHandle" } : {});
      },
      update: (el, binding) => {
        el.querySelectorAll("th:not(.data-table-expand-header)").forEach(draggableEl => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, "sortHandle");
          draggableEl.classList.add("sortHandle");
        });
        Sortable.create(el.querySelector("tr"), binding.value ? { ...binding.value, handle: ".sortHandle" } : {});
      }
    }
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      let hasExpandableFirstColumn =
        this.displayHeaders && this.displayHeaders.length > 0 && this.displayHeaders[0].value == "data-table-expand";
      const headersTmp = this.displayHeaders.slice();
      let oldIndex = evt.oldIndex;
      let newIndex = evt.newIndex;
      if (hasExpandableFirstColumn && newIndex < 1) {
        newIndex = 1;
      }
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.displayHeaders = headersTmp.slice();
      this.anIncreasingNumber += 1;
    }
  },
  watch: {
    headers(value) {
      this.displayHeaders = value;
    }
  }
};
</script>
